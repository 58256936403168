import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import '../Blog.css';

class Blog extends React.Component {
  state = {
    content: '# Periler hikayeyi getiriyor...',
    isPrevDisabled: false,
    isNextDisabled: false,
    rating: 0,               // Kullanıcının verdiği puan
    averageRating: null,     // Ortalama puan
    isRatingDisabled: false, // Oylama kilitli mi
    userRating: null,        // Kullanıcının verdiği puan (önceden varsa)
  };

  componentDidMount() {
    const { slug } = this.props.params;
    this.loadMarkdown(slug);
    this.checkNavigation(slug);
    this.fetchAverageRating(slug); // Ortalama ve kullanıcı puanını al
  }

  componentDidUpdate(prevProps) {
    const { slug } = this.props.params;
    if (slug !== prevProps.params.slug) {
      this.loadMarkdown(slug);
      this.checkNavigation(slug);
      this.fetchAverageRating(slug);
    }
  }

  loadMarkdown = (slug) => {
    import(`../markdown/${slug.replace("0", "")}.md`)
      .then((module) => fetch(module.default))
      .then((res) => res.text())
      .then((text) => {
        if (slug.includes("0")) {
          text = text.replace(/Mecnun/g, 'Mertcan')
            .replace(/Furkan/g, 'Fatih')
            .replace(/Emre/g, 'Efe')
            .replace(/Zeyo/g, 'Zehra')
            .replace(/Senyao/g, 'Seda')
            .replace(/Ensar/g, 'Ertuğ')
            .replace(/Turkish/g, 'Tuğba');

          const titleLineIndex = text.indexOf('\n');
          if (titleLineIndex !== -1) {
            let titleLine = text.substring(0, titleLineIndex).trim();
            
            titleLine += ' - <a>Global Edition</a>';
            text = titleLine + text.substring(titleLineIndex);

            document.title = titleLine.replace(/<a>/g, '').replace(/<\/a>/g, '');
          }
        }

        document.title = text.split('\n')[0].replace('#', '').trim();
        
        this.setState({ content: text });
      }).catch((err) => console.error('Markdown yüklenemedi:', err));
  };

  getAdjacentSlug = (slug, step) => {
    const [prefix, numStr] = [slug.split('-').slice(0, -1).join('-'), slug.split('-').pop()];
    const num = parseInt(numStr, 10);

    if (num + step <= 0) return null;

    const newNum = (num + step).toString();
    const formattedNum = numStr.startsWith('0') ? newNum.padStart(numStr.length, '0') : newNum;

    return `${prefix}-${formattedNum}`;
  };

  checkNavigation = async (slug) => {
    const prevSlug = this.getAdjacentSlug(slug, -1);
    const nextSlug = this.getAdjacentSlug(slug, 1);
    const checkExistence = async (s) => s ? await import(`../markdown/${s.replace("0", "")}.md`).then(() => true).catch(() => false) : false;

    this.setState({
      isPrevDisabled: !(await checkExistence(prevSlug)),
      isNextDisabled: !(await checkExistence(nextSlug))
    });
  };

  handleNavigation = (slug) => {
    this.loadMarkdown(slug);
    this.checkNavigation(slug);
    this.props.history.push(`/blog/${slug}`);
  };

  submitRating = async (rating) => {
    try {
      const response = await fetch(`https://darknight-api.vercel.app/rating`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ rating, slug: this.props.params.slug.replace("0", "") })
      });
      if (response.ok) {
        this.setState({ isRatingDisabled: true, userRating: rating });
        this.fetchAverageRating(this.props.params.slug);
      }
    } catch (error) {
      console.error('Puan gönderme hatası:', error);
    }
  };

  fetchAverageRating = async (slug) => {
    try {
      const response = await fetch(`https://darknight-api.vercel.app/rating?slug=${slug.replace("0", "")}`);
      const data = await response.json();
      this.setState({
        averageRating: data.averageRating,
        isRatingDisabled: data.userHasRated,
        userRating: data.userHasRated ? data.userRating : null,
        rating: data.userHasRated ? data.userRating : 0
      });
    } catch (error) {
      console.error('Ortalama puan alma hatası:', error);
    }
  };

  handleRating = (rating) => {
    if (!this.state.isRatingDisabled) {
      this.setState({ rating });
      this.submitRating(rating);
    }
  };

  render() {
    const { slug } = this.props.params;
    return (
      <div className="blog-post">
        <div className="navigate">
          <button onClick={() => this.handleNavigation(this.getAdjacentSlug(slug, -1))} disabled={this.state.isPrevDisabled}>Önceki</button>
          <button onClick={() => this.handleNavigation(this.getAdjacentSlug(slug, 1))} disabled={this.state.isNextDisabled}>Sonraki</button>
        </div>

        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{this.state.content}</ReactMarkdown>

        <div className="navigate">
          <button onClick={() => this.handleNavigation(this.getAdjacentSlug(slug, -1))} disabled={this.state.isPrevDisabled}>Önceki</button>
          <button onClick={() => this.handleNavigation(this.getAdjacentSlug(slug, 1))} disabled={this.state.isNextDisabled}>Sonraki</button>
        </div>

        <div className="rating-box">
          <div style={{ fontSize: 25 }}>Okuyucu puanları</div>

          <div className="user-rating">
            <div className="average-rating">{this.state.averageRating?.slice(0, -1)}</div>

            <div className="star-rating">
              {[1, 2, 3, 4, 5].map((i) => (
                <span
                  key={i}
                  className={i <= this.state.rating ? 'star selected' : 'star'}
                  onClick={() => this.handleRating(i)}
                  style={{ cursor: this.state.isRatingDisabled ? 'not-allowed' : 'pointer', color: i <= this.state.rating ? 'gold' : 'gray' }}
                >
                  ★
                </span>
              ))}
            </div>
          </div>

          <div style={{ color: "#bbb" }}>{this.state.userRating ? `Verdiğiniz Puan: ${this.state.userRating}` : 'Henüz puan vermediniz.'}</div>
        </div>

        <div className="rating-box">
          <div style={{ fontSize: 25 }}>Okuyucu yorumları</div>

          Yakında...
        </div>
      </div>
    );
  }
}

export default (props) => <Blog {...props} params={useParams()} history={useHistory()} location={useLocation()} />;
