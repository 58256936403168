import React from 'react';
import '../App.css';

import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      particleLoad: false
    }
  }

  componentDidMount() {
    var lastText = "Yazılımcı?";

    window.onscroll = () => {
      var titles = document.querySelectorAll(".sub-title");
      var lineText = document.querySelector(".line-text");

      function change(text) {
        if (lastText !== text) {
          lineText.style.opacity = 0;
  
          setTimeout(() => {
            lineText.style.opacity = 1;
          }, 100);
        }

        lineText.innerText = text;
        lastText = text;
      }

      if (window.scrollY < titles[0].offsetTop - 270)
        change("Yazılımcı?");

      if (window.scrollY > titles[0].offsetTop - 270 && window.scrollY < titles[1].offsetTop - 270)
        change("Teknolojiler");

      if (window.scrollY > titles[1].offsetTop - 270 && window.scrollY < titles[2].offsetTop - 270)
        change("Projeler");

      if (window.scrollY > titles[2].offsetTop - 270)
        change("Sosyal Medya");
    }
    
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      this.setState({ particleLoad: true });
    });
  }

  render() {
    var nickname = window.location.hostname.includes("kerem");
    var version = "v.24.0527";

    document.title = nickname ? "Ahmet Kerem" : "DarkNight";
    document.querySelector("link[rel~='icon']").href = nickname ? "favicon_2.png" : "favicon.png";

    const data = [
      { backgroundColor: "#FFDF00", imgSrc: "https://cdn-icons-png.flaticon.com/512/5968/5968292.png" },
      { backgroundColor: "#12A4DF", imgSrc: "https://cdn.icon-icons.com/icons2/1524/PNG/512/markdown_106519.png" },
      { backgroundColor: "#FFFFFF", imgSrc: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/db/Npm-logo.svg/2560px-Npm-logo.svg.png" },
      { backgroundColor: "#1A73E8", imgSrc: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fmeta-l.cdn.bubble.io%2Ff1678214001507x239515035225292830%2F1_ti5CnGh_T4Kqy5aCTLJRcg.png?w=&h=&auto=compress&dpr=1&fit=max" },
      { backgroundColor: "#bababa", imgSrc: "https://cdn.icon-icons.com/icons2/2148/PNG/512/json_icon_132294.png" },
      { backgroundColor: "#F1502F", imgSrc: "https://git-scm.com/images/logos/downloads/Git-Icon-White.png" },
      { backgroundColor: "#FFFFFF", imgSrc: "https://www.svgrepo.com/show/361653/vercel-logo.svg" },
      { backgroundColor: "#2299F8", imgSrc: "https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/121-css3-512.png" },
      { backgroundColor: "#FF5722", imgSrc: "https://cdn-icons-png.flaticon.com/512/732/732212.png" },
      { backgroundColor: "#303030", imgSrc: "https://cdn-icons-png.flaticon.com/512/5968/5968322.png" },
      { backgroundColor: "#FFFFFF", imgSrc: "https://cdn-icons-png.flaticon.com/512/226/226777.png" }
    ];

    const projectData = [
      { background: "linear-gradient(90deg, #8C52FF, #5E17EB)", link: "https://ovgec.glitch.me", imgSrc: "https://cdn.glitch.com/57fac641-81be-4f1a-b8ab-d3f8edb1751e%2F%C3%B6vge%C3%A7-ikon.png?v=1613084601393" },
      { backgroundColor: "#24252A", link: "https://mootie.dev", imgSrc: "https://assets.mootie.dev/logoWithLittleSpace.png" },
      { special: true, backgroundColor: "#FFFFFF", link: "https://ahmetkeremblog.vercel.app", imgSrc: "https://ahmetkeremblog.vercel.app/images/favicon.png" },
      { backgroundColor: "#2F2D33", link: "https://darkcord-app.netlify.app", imgSrc: "https://darkcord-app.netlify.app/darkcord.png" },
      { backgroundColor: "#2c2f33", link: "https://sinek-bot.glitch.me", imgSrc: "https://assets.mootie.dev/sinek/logo.png" },
      { backgroundColor: "#ffffff", link: "https://tabiblog.netlify.app", imgSrc: "https://tabiblog.netlify.app/favicon.png" }
    ];

    const socialData = [
      { backgroundColor: "#211F1F", link: "https://github.com/darknight2060", imgSrc: "https://static-00.iconduck.com/assets.00/github-icon-2048x1988-jzvzcf2t.png" },
      { backgroundColor: "#FFFFFF", link: "https://youtube.com/@darknight2060", imgSrc: "https://cdn-icons-png.flaticon.com/512/1384/1384060.png" },
      { background: "radial-gradient(circle farthest-corner at 28% 120%, #fcdf8f -16%, #fbd377 16%, #fa8e37 32%, #f73344 48%, transparent 64%), linear-gradient(0deg, #f73344 16%, transparent 72%), linear-gradient(145deg, #3051f1 -48%, #c92bb7 24%)", link: "https://instagram.com/darknight.js", imgSrc: "https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/instagram-white-icon.png" },
      { backgroundColor: "#5865F2", link: "https://discord.com/users/682554962693062707", imgSrc: "https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6cc3c481a15a141738_icon_clyde_white_RGB.png" },
      { backgroundColor: "#D0FFF1", link: "https://glitch.com/@darknight", imgSrc: "https://cdn.icon-icons.com/icons2/2699/PNG/512/glitch_logo_icon_170085.png" }
    ];

    return (
      <div className="App" style={this.state.particleLoad ? {} : { display: "none" }}>
        <Particles id="tsparticles" url="/particles.json" />

        <div className="flex-div">
          <div className="alt-flex">
            <h2 className="line-text">
              Yazılımcı?
            </h2>
          </div>
  
          <div className="vertical-line"></div>
  
          <div>
            <h1 className="title">{nickname ? <div>Ahmet <br/> K. </div> : <div>Dark <br/> Night. </div>} <div className="title-line"></div> <div className="title-line line-mini"></div></h1>
  
            <h2 className="sub-title"><b>{"<"}</b> Teknolojiler <b>{"/>"}</b></h2>
            <div className="boxes">
              {data.map((item, index) => (
                <div className="box" key={index} style={{ background: item.background || item.backgroundColor }}>
                  <img src={item.imgSrc} alt={`Image ${index}`} />
                </div>
              ))
              .map(value => ({ value, sort: Math.random() }))
              .sort((a, b) => a.sort - b.sort)
              .map(({ value }) => value)}
            </div>

            <h2 className="sub-title"><b>{"<"}</b> Projeler <b>{"/>"}</b></h2>
            <div className="boxes">
              {projectData.filter(p => nickname ? true : !p.special).map((item, index) => (
                <a href={item.link} key={index}>
                  <div className="box" style={{ background: item.background || item.backgroundColor }}>
                    <img src={item.imgSrc} alt={`Image ${index}`} />
                  </div>
                </a>
              ))
              .map(value => ({ value, sort: Math.random() }))
              .sort((a, b) => a.sort - b.sort)
              .map(({ value }) => value)}
            </div>

            <h2 className="sub-title"><b>{"<"}</b> Sosyal Medya <b>{"/>"}</b></h2>
            <div className="boxes">
              {socialData.map((item, index) => (
                <a href={item.link} key={index}>
                  <div className="box social" style={{ background: item.background || item.backgroundColor }}>
                    <img src={item.imgSrc} alt={`Image ${index}`} />
                  </div>
                </a>
              ))
              .map(value => ({ value, sort: Math.random() }))
              .sort((a, b) => a.sort - b.sort)
              .map(({ value }) => value)}
            </div>

            <div className="footer">
              <h3>{nickname ? "Ahmet Kerem" : "DarkNight"} tarafından geliştirildi.</h3>
              {version}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default App;
